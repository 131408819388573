'use client';

import {
	BigNum,
	BN,
	calculateAdjustKCost,
	calculateRepegCost,
	convertToNumber,
	MarketType,
	OracleSource,
	PerpMarketAccount,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION,
	SpotMarketAccount,
} from '@drift-labs/sdk';
import { COMMON_UI_UTILS, ENUM_UTILS, MarketId, UIMarket } from '@drift/common';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import useCurrentMarketMarkPrice from 'src/hooks/useCurrentMarketMarkPrice';
import useCurrentPerpMarketAccount from 'src/hooks/useCurrentPerpMarketAccount';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import useInfoForCurrentlySelectedMarket from '../../hooks/useInfoForCurrentlySelectedMarket';
import useMarketInfoDisplayData, {
	MarketData,
} from '../../hooks/useMarketInfoDisplayData';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import Button from '../Button';
import { PriceChangePercentDisplay } from './PriceChangePercentDisplay';
import { Rolling24HrVolumeNumberDisplay } from './Rolling24hrVolumeNumberDisplay';
import StatDisplay from './StatDisplay';
import { ViewDetailsForMarketButton } from './ViewDetailsForMarket';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import UIHelpTextLink from '../UIHelpTextLink';
import SemanticChip from '../Utils/SemanticChip';
import use24hMarketDataFromStore from 'src/hooks/use24hMarketDataFromStore';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import { getIsPreLaunchMarket } from '../PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import PreLaunchMarketIcon from '../PreLaunchMarketAlerts/PreLaunchMarketIcon';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import UI_UTILS from 'src/utils/uiUtils';
import { Swap, Info, Open } from '@drift-labs/icons';
import MobileScrollEdges from '../MobileScrollEdges';
import { DriftWindow } from '../../window/driftWindow';
import useGoToRoute from 'src/hooks/useGoToRoute';
import { MarketOracleInfo } from '../MarketInfo/MarketOracleInfo';
import NumberDisplayV3 from '../Utils/NumberDisplayV3';

interface FundingRateInfoAndTooltipProps {
	selectedMarketId: MarketId;
	marketSymbol: string;
	detailsModalDisplay?: boolean;
	textOnlyDisplay?: boolean;
	unit?: 'h' | 'y';
}

export const FundingRateInfoAndTooltip = memo(
	function FundingRateInfoAndTooltipMemo({
		selectedMarketId,
		marketSymbol,
		detailsModalDisplay,
		textOnlyDisplay,
		unit,
	}: FundingRateInfoAndTooltipProps) {
		const marketData24H = use24hMarketDataFromStore({
			targetMarketType: MarketType.PERP,
			targetMarketIndex: selectedMarketId?.marketIndex,
		})?.[0];

		const marketData = useMarketInfoDisplayData();

		const hasLoadedInitialData = marketData.hasLoadedInitialData;

		const [fundingRateUnit, setFundingRateUnit] = useLocalStorageState(
			'fundingRateUnitPreference',
			unit ?? 'h'
		);

		const toggleFundingRateUnit = useCallback(() => {
			setFundingRateUnit(fundingRateUnit === 'h' ? 'y' : 'h');
		}, [fundingRateUnit]);

		const paymentsPerYear = 24 * 365.25;

		let longFundingRateNum = parseFloat(marketData.longFundingRate);
		let shortFundingRateNum = parseFloat(marketData.shortFundingRate);

		if (fundingRateUnit == 'y') {
			longFundingRateNum *= paymentsPerYear;
			shortFundingRateNum *= paymentsPerYear;
		}

		const longsAreString = longFundingRateNum > 0 ? 'pay' : 'receive';
		const shortsAreString = shortFundingRateNum > 0 ? 'receive' : 'pay';

		const [fundingRate24H, setFundingRate24H] = useState({ long: 0, short: 0 });
		const goToRoute = useGoToRoute();

		useEffect(() => {
			const getAndSetFundingData = async () => {
				if (!marketData24H) return;

				const multiplier = fundingRateUnit === 'h' ? 1 : 24 * 365.25;

				setFundingRate24H({
					long: marketData24H.avgLongFunding * multiplier,
					short: marketData24H.avgShortFunding * multiplier,
				});
			};

			setFundingRate24H({ long: 0, short: 0 });

			getAndSetFundingData();

			const interval = setInterval(getAndSetFundingData, 1000 * 60);

			return () => {
				clearInterval(interval);
			};
		}, [marketData24H?.marketIndex, fundingRateUnit]);

		useEffect(() => {
			setFundingRateUnit(unit);
		}, [unit]);

		const goToFundingHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			goToRoute(`/stats/funding-history?market=${marketSymbol}`);
		};

		const isFundingImbalanced = fundingRate24H.long !== fundingRate24H.short;

		const tooltipContent = (
			<div className="flex flex-col">
				<div className="text-xs">
					Funding rate is 1/24th the average hourly premium calculated every
					hour. If positive, longs pay shorts. If negative, shorts pay longs.
					<br />
					<br />
					{' At this rate, longs would ' +
						longsAreString +
						' ' +
						Math.abs(longFundingRateNum).toFixed(
							fundingRateUnit == 'h' ? 5 : 2
						) +
						(fundingRateUnit != 'h' ? '% APR ' : '% ') +
						'and shorts would ' +
						shortsAreString +
						' ' +
						Math.abs(shortFundingRateNum).toFixed(
							fundingRateUnit == 'h' ? 5 : 2
						) +
						(fundingRateUnit != 'h' ? '% APR ' : '% ') +
						'at the end of the hour.'}
					<UIHelpTextLink
						href="https://docs.drift.trade/trading/funding-rates"
						text="More Info"
						className="mt-2"
					/>
				</div>

				{isFundingImbalanced && (
					<span className="mt-4">
						<span>*Long/Short imbalanced on funding.</span>
					</span>
				)}

				{marketData.estimatedFundingRateCountdownTs <= 0 && (
					<span className="mt-4">
						*The funding is currently due, meaning it will be paid during the
						next Trade or Funding Update.
					</span>
				)}
			</div>
		);

		const fundingRateDisplayPredicted = `${
			Math.abs(longFundingRateNum) >= Math.abs(shortFundingRateNum)
				? longFundingRateNum.toFixed(fundingRateUnit == 'h' ? 5 : 2) + '%'
				: shortFundingRateNum.toFixed(fundingRateUnit == 'h' ? 5 : 2) + '%'
		} ${isFundingImbalanced ? '* ' : ''}`;

		const fundingRateDisplay24h = `${
			Math.abs(fundingRate24H.long) >= Math.abs(fundingRate24H.short)
				? fundingRate24H.long.toFixed(fundingRateUnit == 'h' ? 5 : 2) + '%'
				: fundingRate24H.short.toFixed(fundingRateUnit == 'h' ? 5 : 2) + '%'
		} ${isFundingImbalanced ? '* ' : ''} ${
			fundingRateUnit != 'h' ? ' APR ' : ' '
		}`;

		const fundingRateValues = (
			<div className="flex space-x-2">
				{hasLoadedInitialData ? (
					<div className="flex w-full space-x-1">
						<StatDisplay.Value
							value={
								<span className={`flex items-center text-text-default`}>
									<span>
										{`${fundingRateDisplayPredicted} / ${fundingRateDisplay24h}`}
									</span>
									{!detailsModalDisplay && (
										<Open
											size={14}
											className="ml-1 mb-0.5"
											color={'var(--purple-40)'}
											onClick={goToFundingHistory}
										/>
									)}
								</span>
							}
						/>
					</div>
				) : (
					<SkeletonValuePlaceholder
						className="w-full xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
						loading={true}
					/>
				)}
			</div>
		);

		if (textOnlyDisplay) {
			return (
				<span className="inline-flex items-center">{`${fundingRateDisplayPredicted} / ${fundingRateDisplay24h}`}</span>
			);
		}

		// Details modal style display
		if (detailsModalDisplay) {
			return (
				<div className="flex items-center justify-between w-full py-2 border-b border-container-border">
					<Tooltip
						allowHover
						className="funding-rate-predicted"
						content={tooltipContent}
					>
						<div className="flex flex-col">
							<Text.BODY2 className="flex flex-row items-center text-text-default">
								Funding Rate <Info className="ml-1" />
							</Text.BODY2>
						</div>
					</Tooltip>
					<div
						className="flex flex-row items-center"
						onClick={toggleFundingRateUnit}
					>
						<div
							onClick={toggleFundingRateUnit}
							className="flex flex-col items-end"
						>
							<Text.BODY2>{fundingRateValues}</Text.BODY2>
							<Text.BODY2 className="text-text-secondary pt-0.5">
								Predicted / Last 24h
							</Text.BODY2>
						</div>
						<button className="bg-button-secondary-bg flex flex-row items-center justify-center ml-2 p-1.5 px-2 rounded rounded-sm">
							<Swap size={20} />
						</button>
					</div>
				</div>
			);
		}

		// Funding rate bar style display
		return (
			<Tooltip
				allowHover
				className="funding-rate-predicted"
				content={tooltipContent}
			>
				<div
					className="flex-col hidden xs:flex"
					style={{ minWidth: '10rem', maxWidth: '12rem' }}
				>
					<span className="cursor-pointer" onClick={toggleFundingRateUnit}>
						<Text.MICRO1>
							<StatDisplay
								header="Predicted / 24h Funding Rate"
								//underline={true}
								//className="mb-0.5"
							>
								{fundingRateValues}
							</StatDisplay>
						</Text.MICRO1>
					</span>
				</div>
			</Tooltip>
		);
	}
);

interface OIInfoAndTooltipProps {
	symbol: string;
	baseAssetSymbol: string;
	maxOpenInterest: string;
	longOpenInterest: string;
	shortOpenInterest: string;
	openInterest: string;
}

const OIInfoAndTooltip = memo(function OIInfoAndTooltipMemo({
	symbol,
	baseAssetSymbol,
	maxOpenInterest,
	longOpenInterest,
	shortOpenInterest,
	openInterest,
}: OIInfoAndTooltipProps) {
	return (
		<Tooltip
			content={
				<div className="flex flex-col space-y-2 text-xs">
					<div>Open Interest (OI) is the total size of all open positions.</div>
					<div>
						The MAXIMUM directional (LONG or SHORT) OI allowable for the current
						market is : {maxOpenInterest} {symbol}.
					</div>
					<div>
						<span className="text-positive-green">LONG</span> OI:{' '}
						{longOpenInterest} / {maxOpenInterest} {symbol}.
						<br />
						<span className="text-negative-red">SHORT</span> OI:{' '}
						{shortOpenInterest} / {maxOpenInterest} {symbol}.
					</div>
				</div>
			}
		>
			<div className="flex flex-col">
				<Text.MICRO1>
					<StatDisplay header={`Open Interest`}>
						{openInterest !== '0' ? (
							<div className="flex items-center justify-start space-x-1 text-text-default shrink-0">
								<StatDisplay.Value
									value={`${openInterest} ${baseAssetSymbol}`}
								/>
							</div>
						) : (
							<SkeletonValuePlaceholder
								className="w-full xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
								loading={true}
							/>
						)}
					</StatDisplay>
				</Text.MICRO1>
			</div>
		</Tooltip>
	);
});

type MarketStatusString =
	| 'initialized'
	| 'active'
	| 'fundingPaused'
	| 'ammPaused'
	| 'fillPaused'
	| 'withdrawPaused'
	| 'reduceOnly'
	| 'settlement'
	| 'delisted';

const MarketStatusChip = memo(function MarketStatusChipMemo({
	loading,
	status,
	pausedOperations,
}: {
	loading: boolean;
	status: MarketStatusString;
	pausedOperations: string[];
}) {
	const marketPausedOperations = pausedOperations.filter(
		(pausedOp) =>
			!Object.values(COMMON_UI_UTILS.InsuranceFundOperationsMap).includes(
				pausedOp
			)
	);

	let statusVal = 'grey';
	switch (status) {
		case 'active':
			statusVal = 'green';
			break;
		case 'fundingPaused':
		case 'ammPaused':
		case 'fillPaused':
		case 'withdrawPaused':
			statusVal = 'amber';
			break;
		case 'settlement':
		case 'delisted':
		case 'reduceOnly':
			statusVal = 'red';
			break;
	}

	// set to amber if green/grey but has paused operations
	const hasPausedOperations =
		marketPausedOperations && marketPausedOperations.length > 0;
	statusVal =
		(statusVal === 'green' || statusVal === 'grey') && hasPausedOperations
			? 'amber'
			: statusVal;

	return (
		<>
			{loading === false ? (
				<Tooltip
					content={
						<>
							<div className="text-xs">
								Market Status:{' '}
								<span className="font-bold capitalize text-text-emphasis">
									{status}
								</span>
							</div>
							{hasPausedOperations && (
								<div className="flex justify-between w-full">
									<div>Paused Operations:</div>
									<div className="font-bold text-text-emphasis ml-0.5">
										{pausedOperations.map((operation, index) => {
											return <div key={`paused_op_${index}`}>{operation}</div>;
										})}
									</div>
								</div>
							)}
						</>
					}
				>
					<div className="flex flex-col">
						<SemanticChip
							className="mr-1"
							// @ts-ignore
							status={statusVal}
						/>
					</div>
				</Tooltip>
			) : (
				<div className="flex flex-col w-2 h-full"></div>
			)}
		</>
	);
});

const MarkPriceDisplay = memo(function MarkPriceDisplay({
	loading,
	selectedMarketName,
	price,
	isMobile,
}: {
	loading: boolean;
	selectedMarketName: string;
	price: string;
	isMobile: boolean;
}) {
	const markPrice = price
		? BigNum.from(price, PRICE_PRECISION_EXP)
		: BigNum.zero();

	return (
		<Tooltip
			className={`${isMobile ? 'mr-0' : 'mr-0'} sm:mr-0`}
			content={
				<div className="text-xs">
					The current Mark Price for the market ({selectedMarketName}
					). Mark Price is the average of the bid/ask price.{' '}
				</div>
			}
		>
			<div className="flex flex-col">
				{/* Most of the time the digits (in trade precision) should be less than 6rem wide, sometimes when larger: just allow UI to expand */}
				<div
					className={`overflow-hidden text-lg font-semibold text-text-default font-numeral`}
				>
					<NumberDisplayV3
						value={markPrice}
						formattingProps={{
							displayType: 'price$',
							toPrecision: 6,
							preserveTrailingZeroes: true,
						}}
						displayProps={{
							loading,
							showSkeletonInsteadOfValue: true,
							skeletonDimensionsClassName: 'w-[60px] h-[20px]',
						}}
					/>
				</div>
			</div>
		</Tooltip>
	);
});

const baseState = {
	symbol: '',
	indexPrice: undefined as any,
	fundingRate: `0.00`,
	longFundingRate: `0.00`,
	shortFundingRate: `0.00`,
	estimatedFundingRateCountdownTs: 0,
	lastFundingRateTime: `0`,
	baseVolume: `0`,
	lastFundingRate: `0`,
	quoteVolume: `0`,
	twap: `0`,
	maxOpenInterest: '0',
	openInterest: `0`,
	longOpenInterest: `0`,
	shortOpenInterest: `0`,
	pegMultiplier: `0`,
	periodicity: `0`,
	baseAssetAmount: `0`,
	quoteAssetAmount: `0`,
	unPeggedQuoteAssetAmount: `0`,
	baseAssetAmountLong: `0`,
	baseAssetAmountShort: `0`,
	totalFee: `0`,
	totalFeeMinusDistributions: `0`,
	bias: `0`,
	cumFundingRate: `0`,
	indexTwap: `0`,
	indexErr: `0`,
	indexTwapErr: `0`,
	feePoolSize: `0`,
	twapSpreadPct: `0`,
	cumLongRepegProfit: `0`,
	cumShortRepegProfit: `0`,
	cumLongFundingRate: `0`,
	cumShortFundingRate: `0`,
	oracleTwap: `0`,
	markTwapLive: `0`,
	oracleTwapLive: `0`,
	terminalPrice: `0`,
	oracleSource: OracleSource.PYTH,
	lastOracleSlotDelay: 0,
	currentSlot: 0,
};

export default function FundingRateInfo(props: {
	full?: boolean;
	mobile?: boolean;
	admin?: boolean;
}) {
	const scrollAreaRef = useRef<HTMLDivElement>(null);
	const isMobileScreenSize = useIsMobileScreenSize();

	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const selectedMarketName = useDriftStore(
		(s) => s.selectedMarket.current.marketName
	);
	const selectedMarketBaseAssetSymbol = useDriftStore((s) =>
		s.selectedMarket.current.baseAssetSymbol(true)
	);

	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();

	const marketData = useMarketInfoDisplayData();

	const markPrice = useCurrentMarketMarkPrice();

	const marketAccount = selectedMarketInfo?.info?.account;

	const isLiteMode = useIsLiteMode();

	const oracleEstDelta =
		parseFloat(marketData.oracleTwapLive) - parseFloat(marketData.oracleTwap);
	const markEstDelta =
		parseFloat(marketData.markTwapLive) - parseFloat(marketData.twap);

	const isPreLaunchMarket = getIsPreLaunchMarket(
		selectedMarketId,
		marketAccount
	);

	const pausedOperations = COMMON_UI_UTILS.getPausedOperations(marketAccount);

	const cellsClassName = 'px-2 xl:px-6 flex flex-col justify-center';

	const showOraclePrice =
		marketData.hasLoadedInitialData &&
		selectedMarketInfo.info &&
		marketData?.indexPrice &&
		marketData?.indexPrice !== baseState.indexPrice;

	useEffect(() => {
		if (showOraclePrice) {
			DriftWindow.recordStartupTimeMetric('oraclePriceLoaded');
		}
	}, [showOraclePrice]);

	const oracleSource = selectedMarketId.isPerp
		? (marketAccount as PerpMarketAccount)?.amm?.oracleSource
		: (marketAccount as SpotMarketAccount)?.oracleSource;
	const oracle = selectedMarketId.isPerp
		? (marketAccount as PerpMarketAccount)?.amm?.oracle
		: (marketAccount as SpotMarketAccount)?.oracle;

	const mainContent = (
		<div
			className={`relative flex items-center justify-center w-full border-y sm:border border-container-border bg-container-bg h-full hidden-scroll sm:thin-scroll rounded-rl-sm overflow-x-auto`}
			ref={scrollAreaRef}
		>
			<div
				className={`flex items-stretch w-full justify-between sm:justify-start font-display sm:divide-x divide-container-border whitespace-nowrap`}
			>
				{/* Mark price section */}
				<div className="flex flex-row items-center justify-between px-4 py-2 space-x-3 xl:space-x-4 xl:px-6 sm:py-0">
					<MarketStatusChip
						loading={!marketAccount}
						status={
							marketAccount
								? (ENUM_UTILS.toStr(
										marketAccount?.status
								  ) as MarketStatusString)
								: undefined
						}
						pausedOperations={pausedOperations}
					/>

					<MarkPriceDisplay
						loading={!markPrice || markPrice.eqZero()}
						selectedMarketName={selectedMarketName}
						price={markPrice.toString()}
						isMobile={props.mobile}
					/>

					<Tooltip
						className={`${props.mobile ? 'mr-0' : 'mr-20'} sm:mr-0`}
						content={
							<div className="text-xs">
								The 24h price change for the selected market (
								{selectedMarketName}).{' '}
							</div>
						}
					>
						<div
							//style={{ minWidth: '4rem' }}
							className="flex flex-col left-10"
						>
							<div className={`block overflow-hidden`}>
								<Text.BODY2 light>
									<PriceChangePercentDisplay
										markPrice={markPrice}
										marketId={selectedMarketId}
									/>
								</Text.BODY2>
							</div>
						</div>
					</Tooltip>
				</div>

				{!isLiteMode && (
					<div className={cellsClassName}>
						<Tooltip
							allowHover
							content={
								<div className="text-xs">
									The current Oracle Price of the selected asset (
									{selectedMarketBaseAssetSymbol}).
									<br />
									<code>
										{marketData.indexPrice + ' ± ' + marketData.indexErr}
										<br />
									</code>
									{!isPreLaunchMarket && (
										<MarketOracleInfo
											marketData={marketData}
											oracleSource={oracleSource}
											selectedMarketBaseAssetSymbol={
												selectedMarketBaseAssetSymbol
											}
											oracle={oracle}
											tenSlotOracleDelayText={
												selectedMarketId.isPerp
													? 'The price feed has not updated in the last 10 slots, so AMM might be unavailable for fills.'
													: 'The price feed has not updated in the last 10 slots.'
											}
											confidenceIntervalTooLargeText={
												selectedMarketId.isPerp
													? 'The price feed confidence interval too large, so AMM might be unavailable for fills.'
													: 'The price feed confidence interval too large.'
											}
										/>
									)}
								</div>
							}
						>
							<div className="flex flex-col">
								<Text.MICRO1>
									<StatDisplay header="Oracle Price" leftBorder>
										<div className="flex flex-col">
											{showOraclePrice ? (
												<div className="flex items-center">
													<div>
														<Text.BODY2 className="text-text-default">
															<NumberDisplayV3
																value={BigNum.fromPrint(
																	marketData.indexPrice.toString(),
																	PRICE_PRECISION_EXP
																)}
																formattingProps={{
																	displayType: 'price$',
																	toPrecision: 6,
																}}
															/>
														</Text.BODY2>
													</div>
													<div className="w-6 h-3">
														{isPreLaunchMarket ? (
															<PreLaunchMarketIcon
																color={`var(--text-label)`}
																className="ml-1 relative top-[-3px]"
															/>
														) : UI_UTILS.isPythOracle(oracleSource) ? (
															<img
																src={`/assets/icons/oracles/pyth.svg`}
																className="w-[13px] h-[13px] ml-1"
															/>
														) : (
															<img
																src={`/assets/icons/oracles/switchboard.svg`}
																className="w-[16px] h-[16px] ml-1 relative top-[-3px]"
															/>
														)}
													</div>
												</div>
											) : (
												<SkeletonValuePlaceholder
													className="w-full xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
													loading={true}
												/>
											)}
										</div>
									</StatDisplay>
								</Text.MICRO1>
							</div>
						</Tooltip>
					</div>
				)}

				{selectedMarketId.isPerp && (!isMobileScreenSize || !isLiteMode) && (
					<div className={cellsClassName}>
						<FundingRateInfoAndTooltip
							marketSymbol={marketData.symbol}
							selectedMarketId={selectedMarketId}
						/>
					</div>
				)}

				{!isMobileScreenSize && (
					<>
						{selectedMarketId.isPerp && !isLiteMode && (
							<div className={cellsClassName}>
								<OIInfoAndTooltip {...marketData} />
							</div>
						)}

						<div className={cellsClassName}>
							<Rolling24HrVolumeNumberDisplay
								marketIndex={selectedMarketId.marketIndex}
								marketType={selectedMarketId.marketType}
							/>
						</div>
					</>
				)}

				<div className={cellsClassName}>
					<ViewDetailsForMarketButton
						market={UIMarket.fromMarketId(selectedMarketId)}
						isLiteMode={isLiteMode || isMobileScreenSize}
					/>
				</div>

				{props.admin && (
					<div className={cellsClassName}>
						<Tooltip
							content={
								<div className="text-xs">
									{selectedMarketBaseAssetSymbol + '/USD'}
									<br />
									{UI_UTILS.isPythOracle(oracleSource) ? (
										<>
											{'Pyth Oracle Twap: '}
											<code>
												${marketData.indexTwap} ± {marketData.indexTwapErr}
											</code>
											<br />
										</>
									) : (
										<></>
									)}
									Drift Oracle Twap:{' '}
									<code>
										${marketData.oracleTwap}{' '}
										{oracleEstDelta > 0
											? '+ ' + Math.abs(oracleEstDelta).toFixed(3)
											: '- ' + Math.abs(oracleEstDelta).toFixed(3)}
									</code>
									<br />
									Drift Market Twap:{' '}
									<code>
										${marketData.twap}{' '}
										{markEstDelta > 0
											? '+ ' + Math.abs(markEstDelta).toFixed(3)
											: '- ' + Math.abs(markEstDelta).toFixed(3)}
									</code>
									<br />
									Twap Spread:{' '}
									<code>
										{(
											((parseFloat(marketData.markTwapLive) -
												parseFloat(marketData.oracleTwapLive)) *
												100) /
											parseFloat(marketData.oracleTwapLive)
										).toFixed(5)}
										%
									</code>
								</div>
							}
						>
							<div className="flex-col hidden md:flex">
								<div className="overflow-hidden text-xs text-darkBlue-30">
									More Info...
								</div>
							</div>
						</Tooltip>
					</div>
				)}
			</div>
		</div>
	);

	return (
		<>
			{isMobileScreenSize ? (
				<MobileScrollEdges
					scrollableRef={scrollAreaRef}
					className="w-full"
					edgeColorClass="from-container-bg"
				>
					{mainContent}
				</MobileScrollEdges>
			) : (
				mainContent
			)}

			{props?.full && (
				<ExtraAdminInfo
					marketData={marketData}
					selectedMarketSymbol={selectedMarketName}
				/>
			)}
		</>
	);
}

const ExtraAdminInfo = ({
	marketData,
	selectedMarketSymbol,
}: {
	marketData: MarketData;
	selectedMarketSymbol: string;
}) => {
	const now = new Date();
	const nowUTC = new BN(
		Date.UTC(
			now.getUTCFullYear(),
			now.getUTCMonth(),
			now.getUTCDate(),
			now.getUTCHours(),
			now.getUTCMinutes(),
			now.getUTCSeconds(),
			now.getUTCMilliseconds()
		) / 1000
	);
	const nowBN = new BN((Date.now() / 1000).toFixed(0));
	const unix_milli_timestamp = Number(marketData.lastFundingRateTime) * 1000;
	// Create a new JavaScript Date object based on the timestamp
	// multiplied by 1000 so that the argument is in milliseconds, not seconds.
	const lastFundingDateString = new Date(unix_milli_timestamp);
	const market = useCurrentPerpMarketAccount();
	let adjK1 = new BN(0);
	let adjPeg001 = new BN(0);
	if (market) {
		adjK1 = calculateAdjustKCost(market.amm, new BN(101), new BN(100));
		adjPeg001 = calculateRepegCost(
			market.amm,
			market.amm.pegMultiplier.add(new BN(1))
		).abs();
	}

	return (
		<>
			<div className="relative flex items-center justify-center h-20 px-4 ">
				<div className="flex justify-between w-full">
					<div className="flex items-center font-display">
						{/* {JSON.stringify(props)}  */}
						<div className="flex flex-col mr-6">
							<div className="overflow-hidden text-xs text-neutrals-40">
								PegMultiplier
							</div>

							<div className="overflow-hidden font-semibold font-numeral">
								{marketData.pegMultiplier}
							</div>
						</div>
						<div className="flex flex-col mr-6">
							<div className="overflow-hidden text-xs text-neutrals-40">
								Pyth Oracle Twap
							</div>

							<div className="overflow-hidden font-semibold font-numeral">
								${marketData.indexTwap} ± {marketData.indexTwapErr}
							</div>
						</div>

						<div className="flex flex-col mr-6">
							<div className="overflow-hidden text-xs text-neutrals-40">
								Last Drift Oracle Twap
							</div>

							<div className="overflow-hidden font-semibold font-numeral">
								${marketData.oracleTwap}
							</div>
						</div>

						<div className="flex flex-col mr-6">
							<div className="overflow-hidden text-xs text-neutrals-40">
								Last Drift Mark Twap
							</div>

							<div className="overflow-hidden font-semibold font-numeral">
								${marketData.twap}
							</div>
						</div>

						<div className="flex flex-col mr-6">
							<div className="overflow-hidden text-xs text-neutrals-40">
								TwapSpreadPct
							</div>

							<div className="overflow-hidden font-semibold font-numeral">
								{marketData.twapSpreadPct}%
							</div>
						</div>

						<div className="flex flex-col mr-6">
							<div className="overflow-hidden text-xs text-neutrals-40">
								Number of Users
							</div>

							<div className="overflow-hidden font-semibold font-numeral">
								{marketData.openInterest}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="relative flex h-10 pl-4 justify-left items-left ">
				<div className="flex items-center font-display"></div>
				<br />
				<div className="flex flex-col mr-6">
					<div className="text-xs text-neutrals-40">Funding Calculation</div>

					<div className="font-semibold font-body">
						periodicity ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.periodicity}{' '}
						</span>
						<br></br>
						now ={' '}
						<code className="text-xs text-neutrals-40">
							{' '}
							{nowBN.toString()}, {nowUTC.toString()}, {now.toString()}
						</code>
						<br></br>
						lastFundingTime {'=  '}
						<code className="text-xs text-neutrals-40">
							{' '}
							{marketData.lastFundingRateTime},{' '}
							{lastFundingDateString.toString()}{' '}
						</code>
						<br></br>
						{/* {marketData.fundingRateTime.includes('-') ? "updateFundingRate" : ""} */}
						estNextFundingRate ={' '}
						<code className="text-xs text-neutrals-40">
							{' '}
							{marketData.fundingRate}
							{'%'}
						</code>
						<br></br>
						lastFundingRate ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{'$'}
							{marketData.lastFundingRate}
							{' per '}
							{selectedMarketSymbol}
						</span>
					</div>
				</div>
			</div>
			<div
				style={{
					width: '100px',
					marginRight: 'auto',
					marginTop: '102px',
					marginLeft: '10px',
					fontSize: '8px',
				}}
			>
				<Button.Secondary
					size="MEDIUM"
					disabled={marketData.estimatedFundingRateCountdownTs > 0}
					//onClick={onUpdateFundingRate}
					highlight
					className={`${!(marketData.estimatedFundingRateCountdownTs < 0)}
								? 'background-app-gradient w-full border border-black hover:border-white'
								: 'h-10 normal-case font-semibold'
						}`}
				>
					<div
						className={`flex items-center justify-center text-text-emphasis`}
					>
						{`updateFundingRate`}
					</div>
				</Button.Secondary>
			</div>
			<br />

			<div className="relative flex pl-4 justify-left items-left h-80">
				<div className="flex items-center font-display"></div>

				<div className="flex flex-col mr-6">
					<div className="overflow-hidden text-xs text-neutrals-40">dAMM</div>

					<div className="overflow-scroll font-semibold font-body">
						baseAmt ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.baseAssetAmount} {selectedMarketSymbol}
						</span>
						<br></br>
						quoteAmt ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.quoteAssetAmount} {'pegUSDC'}
						</span>
						<br></br>
						unpeggedQuoteAmt ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.unPeggedQuoteAssetAmount} {'USDC'}
						</span>
						<br></br>
						<br></br>
						baseAmtBias ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.bias} {selectedMarketSymbol}
						</span>
						<br></br>
						baseAmtLong ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.baseAssetAmountLong} {selectedMarketSymbol}
						</span>
						<br></br>
						baseAmtShort ={' '}
						<span className="text-xs text-neutrals-40">
							{' '}
							{marketData.baseAssetAmountShort} {selectedMarketSymbol}
						</span>
						<br></br>
						<br></br>
						terminalPrice ={' '}
						<span className="text-xs text-neutrals-40">
							{'$'}
							{marketData.terminalPrice}
						</span>
						| increase KSqrt 1% Cost ={' '}
						<span className="text-xs text-neutrals-40">
							{'$'}
							{convertToNumber(adjK1, QUOTE_PRECISION).toFixed(3)}
						</span>
						| repeg .001 Cost ={' '}
						<span className="text-xs text-neutrals-40">
							{'$'}
							{convertToNumber(adjPeg001, QUOTE_PRECISION).toFixed(3)}
						</span>
						<div className="relative flex items-center justify-center h-20 pl-4">
							<div className="flex justify-between w-full">
								<div className="flex items-center font-display">
									<div className="flex flex-col mr-10">
										<div className="text-xs text-neutrals-40">
											Total Fee / Cumulative Fee / Fee Pool
										</div>

										<div className="font-semibold font-body">
											{marketData.totalFee}/
											{marketData.totalFeeMinusDistributions}/
											{marketData.feePoolSize}
										</div>
									</div>

									<div className="flex flex-col mr-6">
										<div className="overflow-hidden text-xs text-neutrals-40">
											Cum(Long/Short)FundingRate
										</div>

										<div className="overflow-hidden font-semibold font-body">
											{marketData.cumLongFundingRate}/
											{marketData.cumShortFundingRate}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
